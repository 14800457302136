import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { RootState } from "./store";
import { set } from "lodash";

// Define the structure of each banner message
export interface BannerMessage {
  title: string;
  message: string;
  action?: () => void;
}

export interface ModalMessage {
  title: string;
  desc: string;
  obj?: Record<string, any>;
}

// Define types the state
export type UIState = {
  bannerOpen: boolean;
  bannerMessages: BannerMessage[];
  modalOpen: boolean;
  modalMessages: ModalMessage[];
  closedBannerTitles: string[];
  sideMenuOpen: boolean;
  errorMsg: string;
  newlyInvitedUser: Record<string, any>;
  notificationPriority: number;
  notificationSheetOpen: boolean;
  isLoadedFormPage: boolean;
};

const uISlice = createSlice({
  name: "UI", // Name of the slice
  initialState: {
    bannerOpen: false,
    bannerMessages: [] as BannerMessage[],
    closedBannerTitles: [] as string[],
    modalOpen: false,
    modalMessages: [] as ModalMessage[],
    sideMenuOpen: false,
    errorMsg: "",
    newlyInvitedUser: {},
    notificationPriority: 1,
    notificationSheetOpen: false,
    isLoadedFormPage: false,
  },

  reducers: {
    // This is an example of [type] syntax in a reducer function
    setBannerOpen: (state, action) => {
      state.bannerOpen = action.payload;
    },
    addOrUpdateBannerMessage: (state, action: PayloadAction<BannerMessage>) => {
      const { title, message } = action.payload;

      // Check if a message with this title already exists
      const existingMessageIndex = state.bannerMessages.findIndex((msg) => msg.title === title);

      if (existingMessageIndex !== -1) {
        // Message exists, update it
        state.bannerMessages[existingMessageIndex].message = message;
      } else {
        // New message, add it
        state.bannerMessages.push({ title, message });
      }
    },
    setBannerMessages: (state, action) => {
      state.bannerMessages = action.payload;
    },
    setClosedBannerTitles: (state, action) => {
      state.closedBannerTitles = action.payload;
    },
    setModalOpen: (state, action) => {
      state.modalOpen = action.payload;
    },
    addOrUpdateModalMessage: (state, action: PayloadAction<ModalMessage>) => {
      const { title, desc, obj } = action.payload;

      // Check if a message with this title already exists
      const existingMessageIndex = state.modalMessages.findIndex((msg) => msg.title === title);

      if (existingMessageIndex !== -1) {
        // Message exists, update it
        state.modalMessages[existingMessageIndex].desc = desc;
        state.modalMessages[existingMessageIndex].obj = obj;
      } else {
        // New message, add it
        state.modalMessages.push({ title, desc, obj });
      }
    },
    removeModalMessage: (state, action: PayloadAction<string>) => {
      //remove a modal message by title
      state.modalMessages = state.modalMessages.filter((msg) => msg.title !== action.payload);
    },
    setModalMessages: (state, action) => {
      state.modalMessages = action.payload;
    },
    setSideMenuOpen: (state, action) => {
      state.sideMenuOpen = action.payload;
    },
    setErrorMsg: (state, action) => {
      state.errorMsg = action.payload;
    },
    setNewlyInvitedUser: (state, action) => {
      state.newlyInvitedUser = action.payload;
    },
    setNotificationPriority: (state, action) => {
      state.notificationPriority = action.payload;
    },
    setNotificationSheetOpen: (state, action) => {
      state.notificationSheetOpen = action.payload;
    },
    setIsLoadedFormPage: (state, action) => {
      state.isLoadedFormPage = action.payload;
    },
    resetUISlice: (state) => {
      state.bannerOpen = false;
      state.bannerMessages = [];
      state.closedBannerTitles = [];
      state.modalOpen = false;
      state.modalMessages = [];
      state.sideMenuOpen = false;
      state.errorMsg = "";
      state.newlyInvitedUser = {};
      state.notificationPriority = 1;
      state.notificationSheetOpen = false;
      state.isLoadedFormPage = false;
    },
  },
});

export const {
  setSideMenuOpen,
  setErrorMsg,
  setNewlyInvitedUser,
  setBannerOpen,
  setBannerMessages,
  setClosedBannerTitles,
  addOrUpdateBannerMessage,
  setModalOpen,
  setModalMessages,
  addOrUpdateModalMessage,
  removeModalMessage,
  setNotificationPriority,
  setNotificationSheetOpen,
  setIsLoadedFormPage,
  resetUISlice,
} = uISlice.actions;
export default uISlice.reducer;
