import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { set } from "lodash";

// Define types the state
export type AIDataState = {
  aiFillRequestStarted: number | null;
  handledAIFillRequestStarted: number | null;
  aiFillRequestCompleted: number | null;
  showDismissedChanges: boolean;
};

const aiDataSlice = createSlice({
  name: "data", // Name of the slice
  initialState: {
    aiFillRequestStarted: null,
    handledAIFillRequestStarted: null,
    aiFillRequestCompleted: null,
    showDismissedChanges: false,
  },

  reducers: {
    // This is an example of [type] syntax in a reducer function
    setAIRequestStarted: (state, action) => {
      state.aiFillRequestStarted = action.payload;
    },
    setHandledAIFillRequestStarted(state, action) {
      state.handledAIFillRequestStarted = action.payload;
    },
    setAIRequestCompleted: (state, action) => {
      state.aiFillRequestCompleted = action.payload;
    },
    setShowDismissedChanges: (state, action: PayloadAction<boolean>) => {
      state.showDismissedChanges = action.payload;
    },
    resetUISlice: (state) => {
      state.aiFillRequestStarted = null;
      state.aiFillRequestCompleted = null;
      state.handledAIFillRequestStarted = null;
    },
  },
});

export const { setAIRequestStarted, setHandledAIFillRequestStarted, setAIRequestCompleted, setShowDismissedChanges, resetUISlice } = aiDataSlice.actions;
export default aiDataSlice.reducer;
